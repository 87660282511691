import './App.css';

function App() {
  return (
    <div className="App">
      <main>
        <div className="row">
          <div className="name">
            <h1>
              selena 
              <br/>
              carlson
            </h1>
          </div>
          <div className="text">
            <h2>I build websites. They look nice.</h2>
            <div>Need help building a nice-looking website?</div>
            <a href="mailto:selena.h.carlson@gmail.com">hmu</a>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
